import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import MainTab from '@/views/MainTab.vue';
import TestPage from '../views/TestPage.vue'
import ConsolePage from '../views/ConsolePage.vue'
import ViewportPage from '../views/ViewportPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/',
    component: MainTab,
    children: [
      {
        path: '',
        redirect: '/home',
      },
      {
        path: 'home',
        name: 'Test',
        component: TestPage
      },
      {
        path: 'console',
        name: 'Console',
        component: ConsolePage
      },
      {
        path: 'viewport',
        name: 'Viewport',
        component: ViewportPage
      },
      {
        path: ':page?/:subpage?',
        name: 'Navigated',
        component: TestPage
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
