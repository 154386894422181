import { reactive, toRefs } from 'vue'

export const store = reactive<{
  logs: string[]
}>({ logs: [] })

function logEvent(data: any) {
  let content = '';
  if (typeof data === 'object') {
    content = JSON.stringify(data);
  } else {
    content = data;
  }
  store.logs.push(content);
}

export const useStore = () => {
  return {
    logEvent,
    ...toRefs(store),
  }
}
